import { gsap } from "gsap";
// import { ray } from "node-ray/web";

// Get DOM elements
const fist = document.querySelector(".fist");
const tv_frame = document.querySelector(".tv-frame");
const tv_text = document.querySelector(".tv-text");
const audio_static_file = require("url:./audio-static.wav");
const audio_static = new Audio(audio_static_file);
let audio_refs = ["0", "1", "2", "3", "4"];
let audios = [];

audio_refs.forEach((audio_ref) => {
  // ray(audio_ref).label("audio ref");
  // let file = require(`url:./audio-static-channel-${audio_ref}.mp3`);
  let file = `./audio-static-channel-${audio_ref}.mp3`;
  let audio = new Audio(file);
  // audio.src = `sounds/${note}.mp3`; // load the sound file
  audios[audio_ref] = audio;
});

// audio_static.loop = true;
// audio_static.addEventListener("timeupdate", function () {
//   let buffer = 0.44;
//   if (this.currentTime > this.duration - buffer) {
//     this.currentTime = 0;
//     this.play();
//   }
// });
const audio_tv_pound_file = require("url:./audio-tv-pound.mp3");
const audio_tv_pound = new Audio(audio_tv_pound_file);
const audio_channel_change_file = require("url:./audio-channel-change-1.mp3");
const audio_channel_change = new Audio(audio_channel_change_file);
const special_announcement_1 =
  'Congratulations! You\'re the 99th TV smacker. You\'ve won a sample chapter. Click here to <a href="slarlo.pdf" target="_blank">read Return to Sender</a>.';
const special_announcment_1_number = 10;
const special_announcement_2 =
  'Want a sample chapter? <a href="mailto:arlokenwood@gmail.com">Email me here</a>.';
const special_announcment_2_number = 25;

// Initialize variables
let quotes = ""; // Longest quote is about 414 characters
let click_count = 0;
const random = true;

// Create GSAP timelines
let tl_fist = gsap.timeline({ paused: true, onComplete: fistComplete });
let tl_text = gsap.timeline({ paused: true });

// GSAP animations
// ---------------------------------------------------------------------------
//
// Order:
// 1. Fist click = begin tl_fist
// 2. Fist down 1 and rebound
// 3. Begin showText
// 4. Play audio static
// 5. Fist down 2 and rebound
// 6. Begin tl_text
// 7. Text fade out
// 8. Change text
// 9. Text fade in
// 10.
//
tl_fist.fromTo(
  ".fist",
  { rotation: -30, y: -20 },
  {
    rotation: 0,
    y: "+=20",
    duration: 0.2,
    ease: "back.inOut",
    onComplete: playAudioTvPound,
  },
); // fist down 1
tl_fist.to(".fist", { rotation: "-=20", duration: 0.1, ease: "power1.in" }); // rebound 1
tl_fist.to(".fist", {
  rotation: "+=21",
  duration: 0.2,
  ease: "power4.inOut",
  onComplete: showText,
}); // fist down 2
tl_fist.to(".fist", { rotation: "-=24", duration: 0.3, ease: "back.out" }); // fist up

// tl_text.fromTo(".tv-text-container", {opacity: 0, scale: 0}, {opacity: 1, scale: 1, duration: 0.3, ease:"back.out"});
// tl_text.set(".tv-text-container", {opacity: 0});
tl_text.to(".tv-text-container", {
  opacity: 0,
  duration: 0.3,
  ease: "back.out",
  onComplete: changeText,
});
tl_text.to(".tv-text-container", {
  delay: 0.1,
  opacity: 1,
  duration: 0.3,
  ease: "back.out",
});

// Array shuffle utility
// @see https://stackoverflow.com/a/12646864/1570463
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

function playAudioTvPound() {
  audio_tv_pound.play();
}

// Text handlers
// ---------------------------------------------------------------------------
//
function showText() {
  // ray("begin showText");
  // ray(click_count).label("click count");
  // audio_static.currentTime = 0; // Trigger static sound to correspond with text change
  // audio_static.play(); // Trigger static sound to correspond with text change
  let randomIndex = Math.floor(Math.random() * audio_refs.length);
  audios[randomIndex].currentTime = 0;

  audios[randomIndex].play();
  // audio_tv_pound.currentTime = 0;
  // audio_tv_pound.play();
  //second seek to the specific time you're looking for
  // audio_channel_change.currentTime = 0;
  // audio_channel_change.play();
  tl_text.invalidate().play(0);

  if (click_count === 1) {
    let tv_img_no_signal = document.getElementById("tv-img-no-signal");
    tv_img_no_signal.style.opacity = "0";
  }
}

function changeText() {
  // ray("click count text change: " + click_count);
  if (click_count === 1) {
    // remove initial styles
    tv_text.removeAttribute("style");
    // setTimeout(() => {
    // console.log("Delayed for 1 second.");
    tv_text.textContent = quotes[click_count - 1];
    // }, 1000);
  } else if (click_count === special_announcment_1_number) {
    tv_text.innerHTML = special_announcement_1;
    // console.log('special announcement!');
  } else if (click_count === special_announcment_2_number) {
    tv_text.innerHTML = special_announcement_2;
  } else {
    tv_text.textContent = quotes[click_count - 1];
  }
}

function fistComplete() {
  // tl_fist.reset();
  // tl_text.set(".tv-text-container", {opacity: 0});
  // ray("the fist tl is complete");
  // more code
}

// Fetch quotations
// ---------------------------------------------------------------------------
//
// Call `fetch()`, passing in the URL.
async function getQuotes(url = "") {
  const response = await fetch(url);
  const text = await response.text();

  return text;
}

// Parse quotations as array
(async () => {
  quotes = await getQuotes("./quotations.json");
  quotes = JSON.parse(quotes);
  quotes = quotes.quotations;

  // If randomize is enabled, shuffle the array
  if (random) {
    shuffleArray(quotes);
  }

  // Insert placeholder elements where special announcements will go
  quotes.splice(special_announcment_1_number - 1, 0, "");
  quotes.splice(special_announcment_2_number - 1, 0, "");

  // ray(quotes.length).label("quotes length");
  // ray(quotes).label("quotes");
  // ray(quotes[0], quotes[1]);
})();

// Listen for fist clicks. On click, play the 'fist' timeline.
fist.addEventListener("click", (event) => {
  if (click_count == quotes.length) {
    click_count = 0;
  }

  // ray("begin tl_fist");
  tl_fist.invalidate().play(0);
  click_count++;
});
